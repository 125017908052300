.leaflet-touch .leaflet-bar {
  margin-bottom: 120px;
}

.free {
  color: #037048 !important;
}

.booked {
  color: #ea1d25 !important;
}
