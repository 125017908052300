@import "../../../../lib/scss/mixins";
@import "../../../../lib/scss/variables";

.BookingsMapSingle {
  .BookingsMapHeader {
    .Button .Icon {
      position: unset;
    }
  }

  .adjustable-button {
    .btn:before {
      background: none !important;
    }

    .TimeButton {
      @include mobile-tablet {
        margin-right: toRem(0);
      }
    }

    .SpotlightButton {
      .Button {
        &--subtitle {
          border: 1px solid #fff;
          border-radius: 50%;
          margin-left: toRem(8px);
          width: toRem(23px);
          height: toRem(23px);
          font-size: toRem(13px);
          display: flex;
          align-items: center;
          place-content: center;

          @include mobile-tablet {
            display: none;
          }
        }
      }
    }
  }

  .leaflet-popup-content-wrapper {
    word-break: break-all;
    //max-height: toRem(200px);
    overflow-y: auto;
  }

  .UserLeafletMarker {
    position: relative;
    padding-left: toRem(16px);

    svg {
      bottom: toRem(5px);
      left: toRem(10px);
      position: absolute;
    }

    &__icon {
      position: absolute;
      width: toRem(40px);
      bottom: toRem(23px);
      left: 150%;
      z-index: 1000;

      @include mobile-tablet {
        bottom: toRem(18px);
        left: 115%;
      }

      img {
        width: toRem(30px);
        height: toRem(30px);
      }

      span {
        font-size: toRem(18px);
        color: #fff;
        padding: 0.3rem;
        @include circleBorder;
      }

      img,
      span {
        border: 1px solid #fff;
        border-radius: 50%;
      }
    }
  }

  .BookingsUserPopup {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: toRem(40px);
      height: toRem(40px);
    }

    svg {
      width: toRem(20px);
      margin-top: toRem(10px);
    }

    &__creds {
      font-size: toRem(16px);
      @include circleBorder;
      background-color: $secondary;
      color: #fff;
    }

    img,
    &__creds {
      border: 1px solid #fff;
      border-radius: 50%;
    }

    &__date {
      display: block;
      color: $labelColor;
      padding: toRem(10px 0);
    }

    h6 {
      border-bottom: 1px solid $borderColor;
      padding: toRem(10px 0);
      width: 100%;
      text-align: center;
    }
  }

  .room-permanent-tooltip {
    padding-top: 0;

    span {
      color: #037048;
    }
  }

  .room-booked {
    span {
      color: #ea1d25;
    }
  }
}
